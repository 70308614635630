.fullscreen-width {
    width: 100vw;
}

.fullscreen-height {
    height: 100vh
}

.popup-width {
    width: 400px;
}

.position-absolute {
    position: absolute;
}

.border-radius-m {
    border-radius: 8px;
}

.bg-popup {
    background-color: rgba(0, 0, 0, 0.2);
}

.bg-color_white {
    background-color: white;
}

.displayOnTop {
    z-index: 99999;
}

.padding-m {
    padding: 20px;
}

@media (prefers-reduced-motion: no-preference) {
    .Loading-logo {
        animation: Loading-logo-spin infinite 3s linear;
    }
}

@keyframes Loading-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}



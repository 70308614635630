.calendarDiv {
    /* padding-top: 10px; */
}

.calendarHeaderDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.optionImage {
    width: 20px;
    height: 20px;
}
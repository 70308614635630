.main-holder {
    display: flex;
    overflow-y: hidden;
    align-items: flex-start;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    width: 100vw;
    height: 100vh;
    text-align: center;
}

.transparent-background {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 9998;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
}

.popup-window {
    position: absolute;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 9999;
    overflow-y: scroll;
    box-sizing: border-box;
    padding: 20px;
    max-width: 600px;
    max-height: 90%;
    width: 90%;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 20, 0.05);
    transform: translate(-50%, -50%);

    scrollbar-width: none;
    -ms-overflow-style: none;
}
.company-logo {
    margin-left: 20px;
    height: 30px;
}

.screen-width {
    width: 100vw;
}
.screen-height {
    height: 100vh;
}
.parent-width {
    width: 100%;
}
.parent-height {
    height: 100%;
}
.parent-size {
    width: 100%;
    height: 100%;
}

.row {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    box-sizing: border-box;
    width: 100%;
}
.row-reverse {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    box-sizing: border-box;
    width: 100%;
}
.column {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
}

/* ///// */

.headerCouponButton {
    overflow: hidden;
}

.headerMenuButton {
    overflow: hidden;
    width: 0px;
}

.menu {
    min-width: 230px;
    width: 320px;
    height: 100%;
    padding: 20px;
}
.menuFull {
    width: 100%;
    height: 100%;
    padding: 20px;
}

.mainBody {
    overflow-x: scroll;
    justify-content: flex-start;
    padding: 30px;
    width: 100%;
    height: 100%;
    background-color: white;
}

.mainBodyHidden {
    overflow: hidden;
    padding: 0px;
    width: 0%;
    height: 0%;
}

/* ///// */

.border-box {
    box-sizing: border-box;
}
.display-block {
    display: inline-block;
}

.cursor-hand {
    cursor: pointer;
}

.justify-content-around {
    justify-content: space-around;
}
.justify-content-center {
    justify-content: center;
}
.justify-content-end {
    justify-content: flex-end;
}
.justify-content-between {
    justify-content: space-between;
}
.align-items-center {
    align-items: center;
}
.align-items-start {
    align-items: flex-start;
}
.align-items-end {
    align-items: flex-end;
}

.template-header{
    display: flex;
    cursor: pointer;
    /*border: solid 1px #f2f2f2;*/
    padding: 5px;
    /*align-items: flex-end;*/
    justify-content: space-between;
    /*background-color: #0089CC;*/
    /*color: #FFF;*/
    /*font-family: verdana;*/
}

.template-header-arrow-icon {
    /*position: absolute;*/

    width: 18px;
    height: 18px;
    opacity: 0.8;
}

.children-show {
    max-height: 500px;
    overflow: hidden;
    transition: max-height 0.25s ease-in;
}

.children-hide {
    max-height: 0;
    transition: max-height 0.15s ease-out;
    overflow: hidden;
}

.ic-r {
    height: 24px;
}
.ic-s {
    height: 16px;
}

.ic-m {
    height: 35px;
}

.icon {
    margin-right: 10px;
    width: 30px;
    height: 30px;
}

.ic-l {
    height: 48px;
}

.ic-xl {
    height: 96px;
}

/* BORDER */
.border-s {
    border: solid;
    border-width: 1px;
    border-color: rgb(240, 240, 240);
}
.border-m {
    border: solid;
    border-width: 1px;
    border-color: rgb(220, 220, 220);
}

/* BORDER RADIUS */

.border-radius-s {
    border-radius: 4px;
}
.border-radius-m {
    border-radius: 8px;
}
.border-radius-r {
    border-radius: 12px;
}
.border-radius-xxl {
    border-radius: 35px;
}

/* SHADOW */
.shadow-s {
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.05);
}
.shadow-m {
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
}

.text-gray {
    color: gray;
}

.text-white {
    color: white;
}

.bg-gray {
    background-color: gray;
}
.bg-gray1 {
    background-color: #f4f4f6;
}
.bg-gray2 {
    background-color: #e9ebed;
}
.bg-gray3 {
    background-color: #dfe1e2;
}
.bg-gray4 {
    background-color: #cccfd1;
}
.bg-gray5 {
    background-color: #b7bfc6;
}
.bg-line {
    background-color: rgb(242, 242, 242);
}
.bg-red {
    background-color: red;
}

.bg-blue {
    background-color: #2490ef;
}

.bg-yellow {
    background-color: yellow;
}
.bg-green {
    background-color: green;
}
.bg-white {
    background-color: white;
}
.bg-blue {
    background-color: rgb(28, 115, 232);
}

.pl-r {
    padding-left: 15px;
}
.pr-r {
    padding-right: 15px;
}
.pt-r {
    padding-top: 15px;
}
.pb-r {
    padding-bottom: 15px;
}

.pl-s {
    padding-left: 8px;
}

.pr-s {
    padding-right: 8px;
}

.pt-s {
    padding-top: 8px;
}

.pb-s {
    padding-bottom: 8px;
}

.pl-l {
    padding-left: 32px;
}

.pr-l {
    padding-right: 32px;
}

.pt-l {
    padding-top: 32px;
}

.pb-l {
    padding-bottom: 32px;
}

.ml-r {
    margin-left: 8px;
}

.ml-s {
    margin-left: 6px;
}

.ml-m {
    margin-left: 12px;
}

.mt-r {
    margin-top: 8px;
}

.mt-s {
    margin-top: 6px;
}

.mt-m {
    margin-top: 12px;
}

.mt-l {
    margin-top: 18px;
}

.mt-xl {
    margin-top: 24px;
}

.mt-xxl {
    margin-top: 32px;
}

.mr-r {
    margin-right: 8px;
}

.mr-s {
    margin-right: 6px;
}

.mr-m {
    margin-right: 12px;
}

.mr-l {
    margin-top: 18px;
}

.mr-xl {
    margin-top: 24px;
}

.mr-xxl {
    margin-top: 32px;
}

.mb-r {
    margin-bottom: 8px;
}

.mb-s {
    margin-bottom: 6px;
}

.mb-m {
    margin-bottom: 12px;
}

/* FONT SIZE */

.fs-r {
    font-size: 13px;
}
.fs-xs {
    font-size: 8px;
}
.fs-s {
    font-size: 10px;
}
.fs-m {
    font-size: 12px;
}
.fs-l {
    font-size: 16px;
}
.fs-xl {
    font-size: 20px;
}
.fs-xxl {
    font-size: 24px;
}
.fs-xxxl {
    font-size: 30px;
}

.circle {
    border-radius: 50%;
}

.ic-contact-r {
    width: 30px;
    height: 30px;
}

/* FONT WEIGHT */

.fw-l {
    font-weight: lighter;
}
.fw-r {
    font-weight: normal;
}
.fw-m {
    font-weight: 600;
}
.fw-b {
    font-weight: bold;
}
.fw-h {
    font-weight: bolder;
}

/* TEXT ALIGNMENT */

.text-wrap-none {
    white-space: nowrap;
}
.text-wrap {
    word-wrap: break-word;
}
.text-trailing {
    text-overflow: ellipsis;
}

.text-align-left {
    text-align: left;
}
.text-align-center {
    text-align: center;
}
.text-align-right {
    text-align: right;
}
.text-align-justify {
    text-align: justify;
}

.user-select-none {
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
}

.TextFieldOne {
    width: 100%;
    outline-width: 0;
    outline-style: solid;
    border: solid;
    border-width: 1px;
    border-color: transparent;
    background-color: transparent;
    font-size: 13px;
}

.TextFieldFilter {
    width: 100%;
    outline-width: 0;
    padding: 6px;
    outline-style: solid;
    border: 1px solid #e9ebed;
    border-radius: 4px;
    text-align: center;
    background-color: transparent;
    font-size: 13px;
}

.SignInSignUpPopupTextField {
    width: 95%;
    outline-width: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    outline-style: solid;
    border: 1px solid #e9ebed;
    border-radius: 1px;
    text-align: left;
    background-color: transparent;
    font-size: 13px;
}

.Secure {
    text-security: circle; /* IE/Safari */
    -moz-text-security: circle; /* FireFox */
    -webkit-text-security: circle; /* Chrome/Safari  */
}

.SignInSignUpCountryButton {
    width: 100%;
    padding: 19px 20px;
    border: 1px solid #e9ebed;
}

.Underline {
    text-decoration: underline;
}

.TextFieldFilterError {
    width: 100%;
    outline-width: 0;
    padding: 6px;
    outline-style: solid;
    border: 1px solid red;
    border-radius: 4px;
    text-align: center;
    background-color: transparent;
    font-size: 13px;
}

.textarea-resize-none {
    resize: none;
}

.textarea-resize-outline {
    overflow: auto;
    outline: none;
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.listPickerBG {
    background-color: transparent;
}
.listPickerBG:hover {
    background-color: #f4f4f6;
}

::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
}

@media only screen and (max-width: 600px) {
    .menu {
        min-width: 0px;
        width: 0%;
        padding: 0px;
    }
    .headerMenuButton {
        margin-right: 5px;
        width: 35px;
    }
    .headerCouponButton {
        overflow: hidden;
        width: 0px;
    }
}

@media (prefers-reduced-motion: no-preference) {
    .Loading-logo {
        animation: Loading-logo-spin infinite 3s linear;
    }
}

@keyframes Loading-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.Rotate {
    position: absolute;
    transform: rotate(0.75turn);
}

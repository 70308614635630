.fontStyle {
    text-align: left;
    white-space: pre-wrap;
    word-wrap: normal;
    font-family: "Helvetica Neue", "Arial";
}
.fontStyleBold {
    text-align: left;
    white-space: pre-wrap;
    word-wrap: normal;
    font-weight: bold;
    font-family: "Helvetica Neue", "Arial";
}

.fontSizeNormal {
    font-size: 0.91vw;
}

.fontSizePrint {
    font-size: 1.55vw;
}

/*.font*/

.left-nav {
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    display: block;
    overflow: auto;
    width: 250px;
    height: 100vh;
    background: rgb(238, 238, 238);
    font-size: 13px;
}

.left-nav-title-div {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 15px 0px;
}

.left-nav-title {
    display: block;
    flex: 1 1 0%;
    margin-top: 10px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 90%;
}

.left-nav-link-div {
    display: block;
    padding: 10px;
    line-height: 1.8;
}

.left-nav-link-div-header {
    display: block;
    margin-top: 20px;
    color: rgb(82, 82, 82);
    text-transform: uppercase;
    font-weight: bold;
}

.left-nav-link-div-item {
    display: block;
    padding-left: 10px;
}

.left-nav-link-div-item-href {
    display: block;
    color: black;
    text-decoration: none;
}
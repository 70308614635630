.help-doc {
    padding-left: 50px;
    padding-right: 50px;
    display: block;
    min-height: 100vh;
}

.markdown:after {
    content: " ";
    display: block;
    clear: both;
    height: 80px;
}
.markdown h1,
h2,
h3 {
    position: relative;
    clear: both;
}

.markdown h1 {
    padding: 10px 0px 10px 0px;
    font-size: 32px;
}

.markdown h2 {
    padding: 10px 0px 10px 0px;
    font-size: 24px;
}

.markdown h1,
h2,
h3 {
    position: relative;
    clear: both;
}

<style>.markdown h1 {
    font-family: Menlo, monospace;
    padding: 40px 0px 10px 0px;
    color: rgb(204, 65, 65);
    font-size: 2em;
}

.markdown h1,
.markdown h2 {
    padding-right: 40px;
    font-weight: bold;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font: inherit;
}

h1 {
    display: block;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}
.App {
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: center;
  background-color: rgb(240, 240, 240);
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media print
{
    @page {
      /* margin: 4.76672vw 0 4.5vw 0; */
      size: auto;
    }

    body {
      margin: 0;
      padding: 0;
    }

    /* .row-cell {
      break-inside: avoid;
    } */

    thead {
      display: table-header-group;
      /* break-inside: auto; */
    }

    tfoot {
      display: table-footer-group;
      /* break-inside: auto; */
    }

    table {
      -fs-table-paginate: paginate;
    }

    .break-inside-avoid {
      break-inside: avoid;
    }

    .break-inside-auto {
      break-inside: auto;
    }
    
    .break-before-always {
      page-break-before: always !important;
      -webkit-column-break-before: always !important;
      break-before: always !important;
    }
}

.break-before-always {
  page-break-before: always !important;
  -webkit-column-break-before: always !important;
  break-before: always !important;
}

.RowDiv {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
}
.RowReverseDiv {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  width: 100%;
}
.ColumnDiv {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.App-header {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  background-color: #282c34;
  color: white;
  font-size: calc(10px + 2vmin);
}
.App-link {
  color: #61dafb;
}
.HandCursor {
  cursor: pointer;
}
.MouseCursor {
  cursor: default;
}
.EditableLabel {
  font-size: 12px;
  color: black;
  /*margin-top: 15px;*/
  /*margin-bottom: 5px;*/
  /*margin-left: 3px;*/
  font-weight: 600;
  width: 90%;
  border: none;
  background-color: transparent;
}
.EditableLabel:focus{
  /*outline-width: 0;*/
}
.EditableLabelTableColumn {
  font-size: 12px;
  color: black;
  /*margin-top: 15px;*/
  /*margin-bottom: 5px;*/
  /*margin-left: 3px;*/
  font-weight: 700;
  /*width: 90%;*/
  border: none
}
/*.EditableLabelTableColumn:focus{*/
/*  outline-width: 0;*/
/*}*/
.TextField {
  /*margin-bottom: 10px;*/
  padding: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
  box-sizing: border-box;
  /* box-shadow: 0 0 2px rgba(0, 0, 0, 0.3); */
  outline-width: 0;
  outline-style: solid;
  border: solid;
  border-width: 0px;
  border-color: rgb(230, 230, 230);
  border-radius: 0px;
  background-color: white;
  font-size: 12px;
  color: rgb(60, 60, 60);
}
.VerticalScrollEnabled {
  overflow-y: scroll;
}
.ListPicker {
  /*padding: 10px;*/
  /*width: 99%;*/
  outline: none;
  /*border-color: rgb(230, 230, 230);*/
  /*border-radius: 8px;*/
  color: black;
  appearance: none;
  /* background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");

  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px; */
}
.ColorPicker {
  padding: 3px;
  padding-top: 1px;
  padding-bottom: 1px;
  border: solid;
  border-width: 1px;
  border-color: rgb(200, 200, 200);
  border-radius: 6px;
}
.Menu {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  left: 0px;
  z-index: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 7vh;
  background-color: white;
}
.MenuShadow {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}
.BannnerText {
  /* text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.85); */
}
.ListPickerHolderStyle {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  background-color: rgb(245, 245, 245);
  border-radius: 6px;
  padding: 0px;
  box-sizing: border-box;
  margin-top: 20px;
  border: solid;
  border-width: 1.5px;
  border-color: rgb(230, 230, 230);
  overflow: hidden;
}
.ListPickerArrowStyle {
  position: absolute;
  right: 13px;
  bottom: 11px;
  width: 8px;
  opacity: 0.8;
}
.HeaderRow {
  justify-content: space-between;
  padding: 6px 8px;
  box-sizing: border-box;
  align-items: center;
}
.HeaderButton {
  cursor: pointer;
  font-size: 11px;
  color: rgb(50, 50, 50);
  background-color: rgb(230, 230, 230);
  padding: 3px;
  padding-left: 7px;
  padding-right: 7px;
  border-radius: 8px;
}
.clicked-effect:active {
  background-color: rgb(55, 115, 225);
  color: white;
}
.DownArrow {
  width: 0;
  height: 0;
  border-right: 12px solid transparent;
  border-bottom: 12px solid white;
  border-left: 12px solid transparent;
}
.WindowPopup {
  position: absolute;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9999;
  overflow: scroll;
  padding: 20px;
  width: 80%;
  height: 80%;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
  transform: translate(-50%, -50%);

  scrollbar-width: none;
  -ms-overflow-style: none;
}
.WindowPopup::-webkit-scrollbar {
  display: none;
}
.TransparentBackground {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9998;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
.PageHolder {
  /*box-sizing: border-box;*/
  margin-top: 3vw;
  margin-bottom: 3vw;
  border-radius: 6px;
  background-color: white;
  /*overflow: hidden;*/
}
.DropShadow {
  box-shadow: 10px 2px 10px 2px rgba(0, 0, 0, 0.05);
}
.Shadow {
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
}
.ShadowMedium {
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
}
.ShadowSmall {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.Rotate {
  direction: rtl;
  white-space: nowrap;
  transform: rotate(270deg);
}
.Border {
  border: solid;
  border-width: 1px;
  border-color: rgb(235, 235, 235);
}
.greyBackground {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9998;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}
.Line {
  width:100%;
  height: 1px;
  background-color: rgb(235, 235, 235);
}
.circle {
  border-radius: 50%;
}

/* SHADOW */
.shadow-s {
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.05);
}
.shadow-m {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 1.0);
}

/* BORDER */
.border-s {
  border: 1px solid rgb(240, 240, 240);
}
.border-m {
  border: 2px solid rgb(240, 240, 240);
}


/* Alert Related */
.text-gray {
  color: gray;
}
.text-white {
  color: white;
}
.text-black {
  color: #1d1d1f;
}
.text-red {
  color: #c80505;
}
.text-blue {
  color: #0244bf;
}

/*Background Color*/
.bg-gray {
  background-color: gray;
}
.bg-gray1 {
  background-color: #f4f4f4;
}
.bg-gray2 {
  background-color: #e9ebed;
}
.bg-gray3 {
  background-color: #dfe1e2;
}
.bg-gray4 {
  background-color: #cccfd1;
}
.bg-gray5 {
  background-color: #b7bfc6;
}
.bg-line {
  background-color: rgb(242, 242, 242);
}
.bg-red {
  background-color: rgb(223, 15, 15);
}
.bg-blue {
  background-color: rgb(55, 115, 225);
}
.popupActionButton {
  margin-left: 10px;
  padding: 5px 10px;
  height: 30px;
  outline: none;
  border: 1px none rgb(55, 115, 225);
  border-radius: 8px;
  background-color: rgb(55, 115, 225);
  color: white;
  font-weight: 600;
  cursor: pointer;
}

.nav-link{
  color: #3773e1;
  text-decoration: none;
}

.mt-auto {
  margin-top: auto;
}

.dayDiv {
    display: flex;
    align-self: center;
    flex-flow: row wrap;
    width: 100%;
    height: 100%;
    text-align: center;
}

.dayItemDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    pointer-events: none;
}

.unselectedDateItemDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    pointer-events: fill;
}

.selectedDateItemDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    pointer-events: fill;
}